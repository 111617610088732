<template>
  <div>
    <div class="row mt-4 mt-md-0">
      <div class="col-md-6 d-flex align-items-center">
        <h3 class="color-text f-600">Pedidos</h3>
      </div>
      <div class="col-md-6 d-md-flex justify-content-end">
        <div class="position-relative as-icon">
          <InputText
            id="search"
            class="form-input"
            placeholder="Pesquisar..."
            type="text"
            required
          />
          <feather class="color-text is-18" type="search"></feather>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12" v-if="loading">
        <content-placeholders rounded>
          <content-placeholders-img />
          <content-placeholders-text :lines="4" />
        </content-placeholders>
      </div>

      <div class="col-12" v-if="!loading">
        <DataTable
          :paginator="true"
          :rows="10"
          dataKey="id"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          class="p-datatable-customers"
          :rowsPerPageOptions="[10, 20, 50]"
          currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords}"
          selectionMode="single"
          @row-select="getPedido"
          :value="pedidos"
        >
          <Column field="cliente" header="CLiente" sortable></Column>
          <Column field="data" header="Data" sortable>
                   <template #body="slotProps">
                     {{formatDate(slotProps.data.data)}}
                     </template>
          </Column>
          <Column field="nome" header="Treinamento"></Column>
          <Column field="inscritos" header="Total inscritos" centerColumn sortable>
            <template #body="slotProps">
                            <span
                class="badge badge-success"
                >{{ slotProps.data.inscritos }}</span
              >
             </template> 
            </Column>
          <Column field="status" header="Status" sortable>
            <template #body="slotProps">
              <span
                class="badge badge-warning"
                v-if="slotProps.data.status == 'pendente'"
                >{{ slotProps.data.status }}</span
              >
              <span
                class="badge badge-primary"
                v-if="slotProps.data.status == 'contrato'"
                >{{ slotProps.data.status }}</span
              >
              <span
                class="badge badge-success"
                v-if="slotProps.data.status == 'pago'"
                >{{ slotProps.data.status }}</span
              >
              <span
                class="badge badge-danger"
                v-if="slotProps.data.status == 'cancelado'"
                >{{ slotProps.data.status }}</span
              >
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default {
  name: "Pedidos",
  components: {
    DataTable,
    Column,
  },
  data() {
    return {
      pedidos: [],
      pedidoSingle: "",
      loading: false,
    };
  },
  methods: {
        formatDate(valor) {
      let date = new Date(valor);
      var formatter = new Intl.DateTimeFormat("pt-BR");
      return formatter.format(date);
    },
    getPedido(event) {
      console.log(event);
      this.$http
        .get("/pedido?pedido_id=" + event.data.id)
        .then((response) => {
          this.pedidoSingle = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPedidos() {
      this.loading = true;
      this.$http.get("/pedidos").then((response) => {
        this.loading = false;
        this.pedidos = response.data.pedidos;
      });
    },
  },
  created() {
    this.getPedidos();
  },
};
</script>

<style lang="scss">
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 64em) {
  /deep/ .p-datatable {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4rem 1rem -0.4rem -0.4rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>